import React, { useCallback, useEffect, useState } from "react";
import { toggleForm } from "../../../redux/features/general";
import { useDispatch } from "react-redux";

function Console({ setSearch, search }) {
  let dispatch = useDispatch();
  const [debouncedSearch, setDebouncedSearch] = useState(search || "");

  // Debounce logic: Updates search state after 500ms delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearch(debouncedSearch);
    }, 500);

    return () => clearTimeout(handler);
  }, [debouncedSearch, setSearch]);

  const handleSearch = useCallback((e) => {
    setDebouncedSearch(e.target.value);
  }, []);
  return (
    <div className="flex items-center gap-3">
      <input
        className="focus:outline-primary border w-full p-2 rounded-md"
        placeholder="Search by title..."
        name="search"
        onChange={handleSearch}
        value={debouncedSearch}
      />
      <button
        onClick={() => dispatch(toggleForm({ form: "create_blog" }))}
        className="group  hover:bg-transparent border border-primary bg-primary p-3 rounded-md hover:scale-90 duration-200 transition-all"
      >
        <svg
          width={25}
          height={25}
          className="group-hover:fill-primary fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>plus</title>
          <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
        </svg>
      </button>
      <button
        onClick={() => dispatch(toggleForm({ form: "add_tag" }))}
        className="group  hover:bg-transparent border border-primary bg-primary p-3 rounded-md hover:scale-90 duration-200 transition-all flex justify-center items-center gap-3 text-white"
      >
        <svg
          width={25}
          height={25}
          className="group-hover:fill-primary fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>tag-plus</title>
          <path d="M21.41,11.58L12.41,2.58C12.04,2.21 11.53,2 11,2H4A2,2 0 0,0 2,4V11C2,11.53 2.21,12.04 2.59,12.41L3,12.81C3.9,12.27 4.94,12 6,12A6,6 0 0,1 12,18C12,19.06 11.72,20.09 11.18,21L11.58,21.4C11.95,21.78 12.47,22 13,22C13.53,22 14.04,21.79 14.41,21.41L21.41,14.41C21.79,14.04 22,13.53 22,13C22,12.47 21.79,11.96 21.41,11.58M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M10,19H7V22H5V19H2V17H5V14H7V17H10V19Z" />
        </svg>
      </button>
      <button
        onClick={() => dispatch(toggleForm({ form: "add_author" }))}
        className="group  hover:bg-transparent border border-primary bg-primary p-3 rounded-md hover:scale-90 duration-200 transition-all flex justify-center items-center gap-3 text-white"
      >
        <svg
          width={25}
          height={25}
          className="group-hover:fill-primary fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>account-plus</title>
          <path d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z" />
        </svg>
      </button>
      <button
        onClick={() => dispatch(toggleForm({ form: "edit_author" }))}
        className="group  hover:bg-transparent border border-primary bg-primary p-3 rounded-md hover:scale-90 duration-200 transition-all flex justify-center items-center gap-3 text-white"
      >
        <svg
          width={25}
          height={25}
          className="group-hover:fill-primary fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>account-edit</title>
          <path d="M21.7,13.35L20.7,14.35L18.65,12.3L19.65,11.3C19.86,11.09 20.21,11.09 20.42,11.3L21.7,12.58C21.91,12.79 21.91,13.14 21.7,13.35M12,18.94L18.06,12.88L20.11,14.93L14.06,21H12V18.94M12,14C7.58,14 4,15.79 4,18V20H10V18.11L14,14.11C13.34,14.03 12.67,14 12,14M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4Z" />
        </svg>
      </button>
    </div>
  );
}

export default Console;
