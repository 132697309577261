import React, { useEffect, useState } from "react";
import {
  listTrackRecords,
  meta_details,
  metaCount,
} from "../../../api/services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  setTableData,
  setTableHead,
  setTableParameter,
} from "../../../redux/features/table";

function Dashboard() {
  let dispatch = useDispatch();
  let { perPage, filter, to, from, email, name } = useSelector(
    (state) => state.table
  );

  useEffect(() => {
    handleTable("path_track");
  }, []);

  async function handleTable(card) {
    let cases = ["path_track", "search", "card_click", "enroll_click"];

    let response = await listTrackRecords({
      page: 1,
      perPage,
      parameter: cases.indexOf(card) + 1,
    });

    if (response.status !== 200)
      toast.error("Error while fetching the table data.");

    dispatch(
      setTableParameter({
        parameter: cases.indexOf(card) + 1,
        page: 1,
        total: response?.data?.data?.total,
      })
    );

    dispatch(setTableData(response?.data?.data?.data || []));

    switch (card) {
      case "user_track":
        dispatch(
          setTableHead([
            "Id",
            "Email",
            "User Name",
            "Path",
            "Time Spend",
            "Date & Time",
          ])
        );
        break;

      case "search":
        dispatch(
          setTableHead([
            "Id",
            "Search Keyword",
            "User Name",
            "Email",
            "Date & Time",
          ])
        );
        break;

      case "card_click":
        dispatch(
          setTableHead([
            "Id",
            "Course Title",
            "User Name",
            "Email",
            "Date & Time",
            "UUID",
          ])
        );
        break;

      case "enroll_click":
        dispatch(
          setTableHead([
            "Id",
            "Course Title",
            "URL",
            "Email",
            "User Name",
            "Date & Time",
          ])
        );
        break;

      default:
        dispatch(
          setTableHead([
            "Id",
            "Email",
            "User Name",
            "Path",
            "Time Spend",
            "Date & Time",
          ])
        );
        break;
    }
  }

  // let { refresh } = useSelector((state) => state.general);
  let [count, setCount] = useState({
    path_track: 0,
    search: 0,
    card_click: 0,
    enroll_click: 0,
    new_registration: 0,
  });

  useEffect(() => {
    fetchMeta();
  }, []);

  async function fetchMeta(params) {
    try {
      let res = await metaCount();
      if (res.status !== 200) toast.error("Something went wrong !!!");
      else {
        let { path_track, search, card_click, enroll_click, new_registration } =
          res?.data?.data;
        setCount({
          path_track,
          search,
          card_click,
          enroll_click,
          new_registration,
        });
      }
    } catch (error) {
      toast.error("Something went wrong !!!");
    }
  }
  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-2 items-center text-lg">
        {" "}
        <span className="border border-primary p-2 rounded-full bg-primary">
          <svg
            className="w-5 h-5 fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>account</title>
            <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
          </svg>
        </span>
        USER COUNT <strong> {count.new_registration}.{" "}</strong>
      </div>
      <div className="flex justify-between gap-3">
        {data.map((row) => (
          <div
            onClick={() => handleTable(row.state)}
            className="w-[300px] min-w-[150px] flex-wrap max-w-[300px] h-[150px] gap-2 p-3 flex flex-col justify-center items-center text-white font-semibold rounded-md bg-primary"
          >
            {row.icon}
            <span className="text-3xl">{count[row.state] || 0} </span>
            <span className="text-lg">{row.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

let data = [
  {
    label: "User Path",
    state: "path_track",
    icon: (
      <svg
        width={30}
        height={30}
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <title>road-variant</title>
        <path d="M18.1,4.8C18,4.3 17.6,4 17.1,4H13L13.2,7H10.8L11,4H6.8C6.3,4 5.9,4.4 5.8,4.8L3.1,18.8C3,19.4 3.5,20 4.1,20H10L10.3,15H13.7L14,20H19.8C20.4,20 20.9,19.4 20.8,18.8L18.1,4.8M10.4,13L10.6,9H13.2L13.4,13H10.4Z" />
      </svg>
    ),
  },
  {
    label: "Search",
    state: "search",
    icon: (
      <svg
        width={30}
        height={30}
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <title>text-box-search</title>
        <path d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M7,15V17H9C9.14,18.55 9.8,19.94 10.81,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19A2,2 0 0,1 21,5V13.03C19.85,11.21 17.82,10 15.5,10C14.23,10 13.04,10.37 12.04,11H7V13H10C9.64,13.6 9.34,14.28 9.17,15H7M17,9V7H7V9H17Z" />
      </svg>
    ),
  },
  {
    label: "Card Click",
    state: "card_click",
    icon: (
      <svg
        width={30}
        height={30}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="white"
      >
        <title>mouse-right-click</title>
        <path d="M13 9V1.07C13.7 1.16 14.37 1.33 15 1.59C17.33 2.53 19.11 4.53 19.75 7C19.91 7.64 20 8.31 20 9H13M17.66 7C17.18 5.65 16.23 4.5 15 3.81V7H17.66M11 9V1.07C7.06 1.56 4 4.92 4 9H11M6.34 20.66C4.84 19.16 4 17.12 4 15V11H20V15C20 17.12 19.16 19.16 17.66 20.66C16.16 22.16 14.12 23 12 23C9.88 23 7.84 22.16 6.34 20.66Z" />
      </svg>
    ),
  },
  {
    label: "Enroll Click",
    state: "enroll_click",
    icon: (
      <svg
        width={30}
        height={30}
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <title>cursor-default-click</title>
        <path d="M10.76,8.69A0.76,0.76 0 0,0 10,9.45V20.9C10,21.32 10.34,21.66 10.76,21.66C10.95,21.66 11.11,21.6 11.24,21.5L13.15,19.95L14.81,23.57C14.94,23.84 15.21,24 15.5,24C15.61,24 15.72,24 15.83,23.92L18.59,22.64C18.97,22.46 19.15,22 18.95,21.63L17.28,18L19.69,17.55C19.85,17.5 20,17.43 20.12,17.29C20.39,16.97 20.35,16.5 20,16.21L11.26,8.86L11.25,8.87C11.12,8.76 10.95,8.69 10.76,8.69M15,10V8H20V10H15M13.83,4.76L16.66,1.93L18.07,3.34L15.24,6.17L13.83,4.76M10,0H12V5H10V0M3.93,14.66L6.76,11.83L8.17,13.24L5.34,16.07L3.93,14.66M3.93,3.34L5.34,1.93L8.17,4.76L6.76,6.17L3.93,3.34M7,10H2V8H7V10" />
      </svg>
    ),
  },
];
export default Dashboard;
