import React, { useEffect, useState } from "react";
import { get_list, listTrackRecords } from "../../../api/services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { toggleForm } from "../../../redux/features/general";
import { useNavigate } from "react-router-dom";
import config from "../../../config.json";
import { setTableData, setTableParameter } from "../../../redux/features/table";

const Table = () => {
  const [keys, setKeys] = useState([]);
  const {
    th,
    data,
    page,
    perPage,
    parameter,
    total,
    filter,
    to,
    from,
    email,
    name,
  } = useSelector((state) => state.table);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length) setKeys(Object.keys(data[0]));
  }, [data]);

  useEffect(() => {
    if (filter || email || name || to || from) {
      fetchData(1, true);
      dispatch(setTableParameter({ page: 1, filter: true }));
    } else {
      dispatch(setTableParameter({ page: 1, filter: false }));
      fetchData(1, false);
    }
  }, [filter, email, name, to, from]);

  async function fetchData(currentPage, fireFilter) {
    try {
      let loadPage = currentPage || page;

      // // Corrected condition
      // if (loadPage < 1 || loadPage > Math.ceil(total / perPage)) {
      //   toast.success("One More data available.");
      //   return;
      // }

      console.log(filter, fireFilter);

      let response = await listTrackRecords({
        page: loadPage,
        perPage,
        parameter,
        filter: fireFilter,
        email,
        name,
        to,
        from,
      });

      if (response.status !== 200) {
        toast.error("Error while fetching the data.");
        return;
      }

      // Update Redux store
      dispatch(
        setTableParameter({
          page: loadPage,
          total: response?.data?.data?.total,
        })
      );
      dispatch(setTableData(response?.data?.data?.data || []));
    } catch (error) {
      console.log(error);
      toast.error("Error while fetching the data.");
    }
  }

  return (
    <div>
      <div className="overflow-x-auto">
        <div className="mb-4">
          Total Searched Result ::<strong> {total}</strong>
        </div>
        <table className="min-w-full overflow-scroll border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-left">
              {th.map((header, index) => (
                <th key={index} className="px-4 py-2 border border-gray-300">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100">
                {keys.map((row) => (
                  <td
                    className={`min-w-fit max-w-[400px] overflow-hidden text-wrap break-words px-4 py-2 border border-gray-300`}
                  >
                    {row !== "date"
                      ? item[row]
                      : new Date(item[row]).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata", // Change this based on your time zone
                        })}
                    {/* {item[row] } */}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => fetchData(page - 1, filter)}
          disabled={page <= 1}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-primary group hover:scale-90 transition-all disabled:opacity-50 disabled:pointer-events-none"
        >
          <svg
            width={30}
            height={30}
            className="group-hover:fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>chevron-left</title>
            <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
          </svg>
        </button>
        <span>Page {page}</span>
        {/* {console.log(parseInt(total / perPage), page)} */}
        <button
          disabled={parseInt(total / perPage) <= page}
          onClick={() => fetchData(page + 1, filter)}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-primary group hover:scale-90 transition-all disabled:opacity-50 disabled:pointer-events-none"
        >
          <svg
            width={30}
            height={30}
            className="group-hover:fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>chevron-right</title>
            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

const initialMeta = {
  page: 0, // Start with page 1
  limit: 5, // Items per page
};

const initialTable = {
  th: ["Id", "Title", "Meta Title", "Meta Description", "Status", "Action"],
  data: [],
};

export default Table;
