import React from "react";
import { toggleForm } from "../../../redux/features/general";
import { useDispatch, useSelector } from "react-redux";
import { setTableParameter } from "../../../redux/features/table";

function Console() {
  let dispatch = useDispatch();
  let { email, name, to, from } = useSelector((state) => state.table);

  function handleFilter(e) {
    console.log(e.target.name,e.target.value)
    dispatch(setTableParameter({ [e.target.name]: e.target.value }));
  }

  function filterFilter(status) {
    if (!status)
      dispatch(
        setTableParameter({
          filter: status,
          email: "",
          name: "",
          to: "",
          from: "",
        })
      );
    else
      dispatch(
        setTableParameter({
          filter: status,
        })
      );
  }

  return (
    <div className="flex items-center gap-3">
      <input
        className="focus:outline-primary border w-full p-2 rounded-md"
        placeholder="Search by email..."
        name="email"
        type="email"
        onChange={handleFilter}
        value={email}
      ></input>
      <input
        className="focus:outline-primary border w-full p-2 rounded-md"
        placeholder="Search by name..."
        name="name"
        type="text"
        onChange={handleFilter}
        value={name}
      ></input>
      <input
        className="focus:outline-primary border w-full p-2 rounded-md"
        placeholder="From"
        name="from"
        type="date"
        max={ to || new Date().toISOString().split("T")[0]}
        onChange={handleFilter}
        value={from}
      ></input>
      <span>TO</span>
      <input
        className="focus:outline-primary border w-full p-2 rounded-md"
        placeholder="To"
        name="to"
        type="date"
        min={from} 
        max={new Date().toISOString().split("T")[0]}
        onChange={handleFilter}
        value={to}
      ></input>
      <button
        onClick={() => filterFilter(true)}
        className="group  hover:bg-transparent border border-primary bg-primary p-3 rounded-md hover:scale-90 duration-200 transition-all"
      >
        <svg
          width={20}
          height={20}
          className="group-hover:fill-primary fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>filter</title>
          <path d="M14,12V19.88C14.04,20.18 13.94,20.5 13.71,20.71C13.32,21.1 12.69,21.1 12.3,20.71L10.29,18.7C10.06,18.47 9.96,18.16 10,17.87V12H9.97L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L14.03,12H14Z" />
        </svg>
      </button>
      <button
        onClick={() => filterFilter(false)}
        className="group  hover:bg-primary border border-primary bg-white p-3 rounded-md hover:scale-90 duration-200 transition-all flex justify-center items-center gap-3 text-white"
      >
        <svg
          width={20}
          height={20}
          className="group-hover:fill-white fill-primary"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>filter-off-outline</title>
          <path d="M2.39 1.73L1.11 3L9 10.89V15.87C8.96 16.16 9.06 16.47 9.29 16.7L13.3 20.71C13.69 21.1 14.32 21.1 14.71 20.71C14.94 20.5 15.04 20.18 15 19.88V16.89L20.84 22.73L22.11 21.46L15 14.35V14.34L13 12.35L11 10.34L4.15 3.5L2.39 1.73M6.21 3L8.2 5H16.96L13.11 9.91L15 11.8V10.75L19.79 4.62C20.13 4.19 20.05 3.56 19.62 3.22C19.43 3.08 19.22 3 19 3H6.21M11 12.89L13 14.89V17.58L11 15.58V12.89Z" />
        </svg>
      </button>
    </div>
  );
}

export default Console;
