import React, { useEffect, useState } from "react";
import { get_list } from "../../../api/services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { toggleForm } from "../../../redux/features/general";
import { useNavigate } from "react-router-dom";
import config from '../../../config.json'

const Table = ({search}) => {
  const BaseUrl = config.dev ? config.localURLFrontEnd : config.liveURL;
  const [meta, setMeta] = useState({...initialMeta,search});
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(1); // Track total pages
  const { refresh } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchBlog();
  }, [meta.page, refresh, search]);

  async function fetchBlog() {
    try {
      const res = await get_list({...meta,search});

      if (res.status === 200) {
        const { list: fetchedList, total } = res.data; // Ensure `total` is returned from the API
        setList(fetchedList); // Update the current page's list
        setTotalPages(Math.floor(total / meta.limit)); // Calculate total pages
      } else {
        toast.success("Looks like all blogs have been fetched.");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  }

  const handlePageChange = (newPage) => {
    // Ensure the page is within valid bounds
    if (newPage >= 0 && newPage <= totalPages) {
      setMeta((old) => ({ ...old, page: newPage }));
    }
  };

  const th = [
    "Id",
    "Title",
    "Meta Title",
    "Meta Description",
    "Status",
    "Action",
  ];

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-left">
              {th.map((header, index) => (
                <th key={index} className="px-4 py-2 border border-gray-300">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="px-4 py-2 border border-gray-300">{item.id}</td>
                <td className="px-4 py-2 border border-gray-300">
                  {item.title}
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  {item.meta_title}
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  {item.meta_description}
                </td>
                <td className="px-4 py-2 border border-gray-300 ">
                  {item.is_active ? (
                    <div className="w-8 h-6  bg-green-400 m-auto text-white rounded-lg flex justify-center items-center">
                      <svg
                        width={20}
                        fill="white"
                        height={20}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <title>check</title>
                        <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                      </svg>
                    </div>
                  ) : (
                    <div className="w-8 h-6 bg-red-400 m-auto rounded-lg flex justify-center items-center text-white">
                      <svg
                        width={20}
                        fill="white"
                        height={20}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <title>close</title>
                        <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                      </svg>
                    </div>
                  )}
                </td>
                <td className="px-4 py-2 border flex items-center justify-center border-gray-300">
                  <div className="flex gap-4 ">
                    {/* edit */}
                    <button
                      onClick={() =>
                        dispatch(
                          toggleForm({ form: "edit_blog", slug: item.slug })
                        )
                      }
                      className="group hover:bg-white bg-primary border border-primary p-1 rounded-lg"
                    >
                      <svg
                        className="fill-white group-hover:fill-primary"
                        width={25}
                        height={20}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <title>pencil</title>
                        <path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                      </svg>
                    </button>
                    {/* preview */}
                    <button
                      onClick={() =>
                        window.location.href = `${BaseUrl}/blog-details/${item.slug}`
                      }
                      className="group hover:bg-white bg-primary border border-primary p-1 rounded-lg"
                    >
                      <svg
                        className="fill-white group-hover:fill-primary"
                        width={25}
                        height={20}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <title>eye-outline</title>
                        <path d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(meta.page - 1)}
          disabled={meta.page <= 0}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-primary group hover:scale-90 transition-all disabled:opacity-50 disabled:pointer-events-none"
        >
          <svg
            width={30}
            height={30}
            className="group-hover:fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>chevron-left</title>
            <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
          </svg>
        </button>
        <span>Page {meta.page + 1}</span>
        <button
          disabled={totalPages <= meta.page}
          onClick={() => handlePageChange(meta.page + 1)}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-primary group hover:scale-90 transition-all disabled:opacity-50 disabled:pointer-events-none"
        >
          <svg
            width={30}
            height={30}
            className="group-hover:fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>chevron-right</title>
            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

const initialMeta = {
  page: 0, // Start with page 1
  limit: 5, // Items per page
};

export default Table;
