import React, { useEffect, useState } from "react";
import TextArea from "./TinyMce";
import Previews from "./ImageDropzone";
import {
  add_author,
  edit_author,
  get_author_by_id,
  list_author,
} from "../../../api/services";
import toast from "react-hot-toast";
import { toggleForm, toggleRefresh } from "../../../redux/features/general";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../../assets/img/user.webp";

function EditAuthor() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.general);
  const [value, setValue] = useState({ ...initialState });
  const [list, setAuthorList] = useState({ page: 0, authors: [] });

  useEffect(() => {
    listTheAuthors();
  }, []);

  async function handelSelect(e) {
    try {
      let { value } = e.target;

      let authorDetails = await get_author_by_id({ id: value });

      if (authorDetails.status !== 200)
        toast.error("Error while fetching the Authors.");

      let {
        data: {
          author: { id, author_name, description, status, author_img },
        },
      } = authorDetails;

      setValue((old) => ({
        ...old,
        author_name,
        description,
        status,
        previewImg : author_img,
        id,
      }));
    } catch (error) {
      toast.error("Error while fetching the Authors.");
    }
  }

  async function listTheAuthors() {
    try {
      let authors = await list_author({ page: list.page });

      if (authors.status !== 200)
        toast.error("Error while fetching the Authors.");

      let {
        data: { list: authorsList, page },
      } = authors;

      if (!authorsList) toast.success("All authors fetched.");

      setAuthorList((old) => ({ ...old, page, authors: authorsList }));
    } catch (error) {
      toast.error("Error while fetching the Authors.");
    }
  }

  function handleValue(e) {
    setValue((old) => ({
      ...old,
      [e.target.name]:
        e.target.name !== "status" ? e.target.value : e.target.checked,
    }));
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      const res = await edit_author(value);
      if (res.status === 200) {
        toast.success("Author updated successfully ");
        dispatch(toggleForm());
        dispatch(toggleRefresh());
        setValue({ ...initialState });
      } else toast.error("Error while updating the author.");
    } catch (error) {
      toast.error("Error while updating the author.");
    }
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="header text-gray-600 flex flex-col gap-1">
        <span className="text-2xl">Edit Author</span>
        <span className="text-xs">
          Make searching experience more enjoyable...
        </span>
      </div>
      {/* // form started */}
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <select
          onChange={handelSelect}
          value={value.id}
          className={inputClass}
          required
          placeholder="Select author to edit."
          name="id"
        >
          {list.authors.map((row, index) => (
            <option value={row.id}>{row.author_name}</option>
          ))}
          <option selected={true} value={0}>
            {"Select the author"}
          </option>
        </select>
        <input
          onChange={handleValue}
          value={value.author_name}
          className={inputClass}
          required
          placeholder="Author Name"
          name="author_name"
        ></input>
        <label for="preview" className="text-gray-600 text-sm">
          Preview author profile image.
          <img
            className="py-5"
            id="preview"
            width={70}
            height={70}
            src={value.previewImg ? value.previewImg : defaultImg}
            alt={value.author_name}
          />
        </label>
        <Previews
          label={"Add Author profile image here."}
          fieldName="author_img"
          setValue={setValue}
        />
        <TextArea
          value={value.description}
          fieldName="description"
          label="Add about author here..."
          setValue={setValue}
        />
        {/* // Tag Checks ends */}
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={value.status}
            name="status"
            onChange={handleValue}
            className="hidden peer"
          />
          <div className="h-4 p-2 w-4 border-2 border-gray-300 rounded-md flex items-center justify-center peer-checked:bg-primary">
            <svg
              width={25}
              height={25}
              className="h-3 w-3 fill-white peer-checked:block hidden"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>check</title>
              <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
            </svg>
          </div>
          <span className="text-gray-600">
            Check the box if want add the author name in author list.
          </span>
        </label>
        {/* // submission  */}
        <button
          disabled={loading}
          type="submit"
          className="bg-primary transition duration-300 hover:bg-white group hover:text-primary border-primary border p-2 text-white font-semibold rounded-lg flex gap-1 justify-center items-center"
        >
          {loading ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="animate-spin group-hover:fill-primary fill-white"
                width={30}
                height={25}
              >
                <title>loading</title>
                <path d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            </>
          ) : (
            <div className="flex items-center">
              <svg
                className=" group-hover:fill-primary fill-white"
                width={30}
                height={25}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>plus</title>
                <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
              </svg>
              Add Author
            </div>
          )}
        </button>
      </form>
      {/* // form end */}
    </div>
  );
}

const inputClass = "outline-primary p-3 border rounded-lg";

const initialState = {
  author_name: "",
  author_img: "",
  description: "",
  status: false,
  previewImg : null,
  id: null,
};
export default EditAuthor;
