import React, { useEffect, useState } from "react";
import Layout from "./layout/Layout";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import store from "./redux/store";
import { Provider, useSelector } from "react-redux";
import Blogs from "./pages/blogs/Blogs";
import Login from "./pages/auth/Login";
import UserTrack from "./pages/user_track/UserTrack";

function App() {
  return (
    <>
        <Layout>
          <Routes>
             <Route element={<Blogs/>} path="/blogs"></Route>
             <Route element={<UserTrack/>} path="/user-track"></Route>
             <Route element={<Login/>} path="/login"></Route>
             <Route element={<Login/>} path="*"></Route>
          </Routes>
        </Layout>
    </>
  );
}

export default function RootApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
