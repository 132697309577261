import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleForm } from "../../redux/features/general";
import BlogForm from "../../pages/blogs/components/BlogForm";
import BlogFormEdit from "../../pages/blogs/components/BlogFormEdit";
import AddTags from "../../pages/blogs/components/AddTags";
import AddAuthor from "../../pages/blogs/components/AddAuthor";
import EditAuthor from "../../pages/blogs/components/EditAuthor";

function FormWrapper() {
  const dispatch = useDispatch();
  const { open_form, form } = useSelector((state) => state.general);

  return (
    <div
      className={`z-10 fixed overflow-auto top-0 h-[100vh] w-[600px] flex flex-col p-5 transition-transform duration-500 bg-white shadow-lg ${
        open_form ? "translate-x-0" : "translate-x-full"
      }`}
      style={{
        right: 0,
        visibility: open_form ? "visible" : "hidden",
        transitionDelay: open_form ? "0s" : "0.5s", // Delays hiding after animation
      }}
    >
      <button
        className="self-end group p-2"
        onClick={() => dispatch(toggleForm(null))}
      >
        <svg
          className="fill-gray-600 group-hover:fill-primary"
          width={30}
          height={25}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>close</title>
          <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
      </button>
      {form === "create_blog" && <BlogForm />}
      {form === "edit_blog" && <BlogFormEdit />}
      {form === "add_tag" && <AddTags/>}
      {form === "add_author" && <AddAuthor/>}
      {form === "edit_author" && <EditAuthor/>}
    </div>
  );
}

export default FormWrapper;
