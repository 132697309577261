// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  th: ["Id", "Email", "User Name", "Path", "Time Spend", "Date & Time"],
  data: [],
  parameter: 1,
  page: 0,
  perPage: 5,
  email: undefined,
  name: undefined,
  to: undefined,
  from: undefined,
  filter: false,
  total: 0,
};

const tableSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setTableParameter: (state, action) => {
      Object.assign(state, action.payload);
    },
    setTableHead: (state, action) => {
      state.th = [...action?.payload];
    },
    setTableData: (state, action) => {
      state.data = [...action?.payload];
    },
  },
});

export const { setTableData, setTableHead, setTableParameter } =
  tableSlice.actions;
export default tableSlice.reducer;
